/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";

import type { IMerkleTreeManager } from "../IMerkleTreeManager";

export class IMerkleTreeManager__factory extends ContractFactory {
  constructor(signer?: Signer) {
    super(_abi, _bytecode, signer);
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<IMerkleTreeManager> {
    return super.deploy(overrides || {}) as Promise<IMerkleTreeManager>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): IMerkleTreeManager {
    return super.attach(address) as IMerkleTreeManager;
  }
  connect(signer: Signer): IMerkleTreeManager__factory {
    return super.connect(signer) as IMerkleTreeManager__factory;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IMerkleTreeManager {
    return new Contract(address, _abi, signerOrProvider) as IMerkleTreeManager;
  }
}

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "merkleRootMap",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "nextTreeId",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "merkleRoot",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "treeId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
    ],
    name: "isProven",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "newMerkleRoot",
        type: "bytes32",
      },
    ],
    name: "addTree",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const _bytecode =
  "0x6080604052600160005534801561001557600080fd5b5061030f806100256000396000f3fe608060405234801561001057600080fd5b50600436106100575760003560e01c80633c70b3571461005c57806344bcce731461009e5780634e64c504146100ec578063693d0d781461012e578063d799160f1461015c575b600080fd5b6100886004803603602081101561007257600080fd5b810190808035906020019092919050505061017a565b6040518082815260200191505060405180910390f35b6100d4600480360360408110156100b457600080fd5b810190808035906020019092919080359060200190929190505050610197565b60405180821515815260200191505060405180910390f35b6101186004803603602081101561010257600080fd5b81019080803590602001909291905050506101fb565b6040518082815260200191505060405180910390f35b61015a6004803603602081101561014457600080fd5b8101908080359060200190929190505050610213565b005b61016461024b565b6040518082815260200191505060405180910390f35b600060016000838152602001908152602001600020549050919050565b60008061010083816101a557fe5b049050600061010084816101b557fe5b06905060006002600087815260200190815260200160002060008481526020019081526020016000205490506000826001901b9050808183161494505050505092915050565b60016020528060005260406000206000915090505481565b80600160008054815260200190815260200160002081905550610242600160005461025190919063ffffffff16565b60008190555050565b60005481565b6000808284019050838110156102cf576040517f08c379a000000000000000000000000000000000000000000000000000000000815260040180806020018281038252601b8152602001807f536166654d6174683a206164646974696f6e206f766572666c6f77000000000081525060200191505060405180910390fd5b809150509291505056fea2646970667358221220ab167c49c09f7f4989f444105ec4c89649cb119d6b7aa24a6ef2ba23af4d80be64736f6c63430007060033";
