/*
 *     Copyright (C) 2021 TART K.K.
 *
 *     This program is free software: you can redistribute it and/or modify
 *     it under the terms of the GNU General Public License as published by
 *     the Free Software Foundation, either version 3 of the License, or
 *     (at your option) any later version.
 *
 *     This program is distributed in the hope that it will be useful,
 *     but WITHOUT ANY WARRANTY; without even the implied warranty of
 *     MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *     GNU General Public License for more details.
 *
 *     You should have received a copy of the GNU General Public License
 *     along with this program.  If not, see https://www.gnu.org/licenses/.
 */

import * as React from "react";
import { Link } from "react-router-dom";
import { Button, Box } from "@material-ui/core";

export interface LinkOutlineButtonProps {
  readonly m: number;
  readonly path: string;
  readonly text: string;
  readonly color?: "inherit" | "primary" | "secondary" | "default" | undefined;
}

const LinkOutlineButton: React.FC<LinkOutlineButtonProps> = ({
  m,
  path,
  text,
  color,
}) => (
  <Button
    component={Link}
    to={path}
    style={{ textDecoration: "none" }}
    color={color}
  >
    {text}
  </Button>
);

export default LinkOutlineButton;
