/*
 *     Copyright (C) 2021 TART K.K.
 *
 *     This program is free software: you can redistribute it and/or modify
 *     it under the terms of the GNU General Public License as published by
 *     the Free Software Foundation, either version 3 of the License, or
 *     (at your option) any later version.
 *
 *     This program is distributed in the hope that it will be useful,
 *     but WITHOUT ANY WARRANTY; without even the implied warranty of
 *     MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *     GNU General Public License for more details.
 *
 *     You should have received a copy of the GNU General Public License
 *     along with this program.  If not, see https://www.gnu.org/licenses/.
 */

import { CampaignInfo } from "../interfaces";

export interface TokenCampaignsState {
  campaigns: CampaignInfo[];
}

export const initialState: TokenCampaignsState = {
  campaigns: [],
};

export type TokenCampaignsAction = {
  type: "campaigns:set";
  payload: {
    campaigns: CampaignInfo[];
  };
};

export const tokenCampaignsReducer = (
  state: TokenCampaignsState,
  action: TokenCampaignsAction
): TokenCampaignsState => {
  switch (action.type) {
    case "campaigns:set":
      return {
        ...state,
        campaigns: action.payload.campaigns,
      };
  }
};
