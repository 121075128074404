/*
 *     Copyright (C) 2021 TART K.K.
 *
 *     This program is free software: you can redistribute it and/or modify
 *     it under the terms of the GNU General Public License as published by
 *     the Free Software Foundation, either version 3 of the License, or
 *     (at your option) any later version.
 *
 *     This program is distributed in the hope that it will be useful,
 *     but WITHOUT ANY WARRANTY; without even the implied warranty of
 *     MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *     GNU General Public License for more details.
 *
 *     You should have received a copy of the GNU General Public License
 *     along with this program.  If not, see https://www.gnu.org/licenses/.
 */

import * as React from "react";
import { Box, Typography, Card, Modal } from "@material-ui/core";
import WalletButton from "../../atoms/WalletButton";
import styled from "styled-components";

export interface ConnectModalProps {
  open: boolean;
  onClose: () => void;
}

const ConnectModal: React.FC<ConnectModalProps> = ({ open, onClose }) => (
  <Modal
    disablePortal
    disableEnforceFocus
    disableAutoFocus
    open={open}
    onClose={() => onClose()}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Card>
      <Box p={4}>
        <Typography variant={"body1"}>
          If you want to continue, please connect to the wallet
        </Typography>
        <ButtonWrapper mt={2} textAlign="center">
          <WalletButton />
        </ButtonWrapper>
      </Box>
    </Card>
  </Modal>
);

const ButtonWrapper = styled(Box)`
  & > div {
    display: block;
    text-align: center;
  }
`;

export default ConnectModal;
