/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";

import type { SocialTokenConstants } from "../SocialTokenConstants";

export class SocialTokenConstants__factory extends ContractFactory {
  constructor(signer?: Signer) {
    super(_abi, _bytecode, signer);
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<SocialTokenConstants> {
    return super.deploy(overrides || {}) as Promise<SocialTokenConstants>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): SocialTokenConstants {
    return super.attach(address) as SocialTokenConstants;
  }
  connect(signer: Signer): SocialTokenConstants__factory {
    return super.connect(signer) as SocialTokenConstants__factory;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): SocialTokenConstants {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as SocialTokenConstants;
  }
}

const _abi = [
  {
    inputs: [],
    name: "distributionRatio",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "hundredPercent",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "vestingRatio",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x61011d610026600b82828239805160001a60731461001957fe5b30600052607381538281f3fe730000000000000000000000000000000000000000301460806040526004361060515760003560e01c806318160ddd146056578063193a14d61460725780636553088414608e5780638a9cb3611460aa575b600080fd5b605c60c6565b6040518082815260200191505060405180910390f35b607860d5565b6040518082815260200191505060405180910390f35b609460db565b6040518082815260200191505060405180910390f35b60b060e1565b6040518082815260200191505060405180910390f35b6a084595161401484a00000081565b6107d081565b611f4081565b6127108156fea26469706673582212203c411ca722af6733245247c95a2b9cd874aa595fd3680e4027292643811d75ed64736f6c63430007060033";
