/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";

import type { PropertyMock } from "../PropertyMock";

export class PropertyMock__factory extends ContractFactory {
  constructor(signer?: Signer) {
    super(_abi, _bytecode, signer);
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<PropertyMock> {
    return super.deploy(overrides || {}) as Promise<PropertyMock>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): PropertyMock {
    return super.attach(address) as PropertyMock;
  }
  connect(signer: Signer): PropertyMock__factory {
    return super.connect(signer) as PropertyMock__factory;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): PropertyMock {
    return new Contract(address, _abi, signerOrProvider) as PropertyMock;
  }
}

const _abi = [
  {
    inputs: [],
    name: "author",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_nextAuthor",
        type: "address",
      },
    ],
    name: "changeAuthor",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_name",
        type: "string",
      },
    ],
    name: "changeName",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_symbol",
        type: "string",
      },
    ],
    name: "changeSymbol",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_sender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_value",
        type: "uint256",
      },
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const _bytecode =
  "0x608060405234801561001057600080fd5b5061025e806100206000396000f3fe608060405234801561001057600080fd5b50600436106100575760003560e01c80635353a2d81461005c578063a3895fff146100d5578063a6c3e6b91461014e578063d13219e814610182578063f3fef3a3146101c6575b600080fd5b6100d36004803603602081101561007257600080fd5b810190808035906020019064010000000081111561008f57600080fd5b8201836020820111156100a157600080fd5b803590602001918460018302840111640100000000831117156100c357600080fd5b9091929391929390505050610214565b005b61014c600480360360208110156100eb57600080fd5b810190808035906020019064010000000081111561010857600080fd5b82018360208201111561011a57600080fd5b8035906020019184600183028401116401000000008311171561013c57600080fd5b9091929391929390505050610218565b005b61015661021c565b604051808273ffffffffffffffffffffffffffffffffffffffff16815260200191505060405180910390f35b6101c46004803603602081101561019857600080fd5b81019080803573ffffffffffffffffffffffffffffffffffffffff169060200190929190505050610221565b005b610212600480360360408110156101dc57600080fd5b81019080803573ffffffffffffffffffffffffffffffffffffffff16906020019092919080359060200190929190505050610224565b005b5050565b5050565b600090565b50565b505056fea2646970667358221220fedce569c5a96b53808d11edbf97059c10d66563399ea57ae76abe78cf08a00d64736f6c63430007060033";
